export default {
  cake: {
    369: '0x9F8182aD65c53Fd78bd07648a1b3DDcB675c6772',
    97: '',
  },
  sosa: {
    369: '0x307f720a51315dC5597210814aA124a9958a8DBC',
    97: '',
  },
  nft: {
    369: '0xA7A6c00b842fbE9061C8A9Bab390b7E8e2733433',
    97: '',
  },
  yieldhub: {
    369: '0xE6608147caFA2E419C340Ec435CdA1a840fCA5EB',
    97: '',
  },
  nftAuction: {
    369: '0xf39ACd89CAb8872e3692E257752c2b9A4fAe1D0B',
    97: '',
  },
  nftAuction_old: {
    369: '0x06097731F3D65DA91c76200C8aF4fDB775c00d71',
    97: '',
  },
  masterChef: {
    369: '0xAc6fBc06C8c0477ba8fc117adb52881c1Cc580dA',
    97: '',
  },
  wbnb: {
    369: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
    97: '',
  },
  ebtc: {
    369: '0xb17d901469b9208b17d916112988a3fed19b5ca1',
    97: '',
  },
  plsx: {
    369: '0x95B303987A60C71504D99Aa1b13B4DA07b0790ab',
    97: '',
  },
  pbtc: {
    369: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    97: '',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    369: '0x5C18524C3FcAA41F9F4a2eD62F41836E2F0E9766',
    97: '0x5C18524C3FcAA41F9F4a2eD62F41836E2F0E9766',
  },
  busd: {
    369: '0x15d38573d2feeb82e7ad5187ab8c1d52810b1f07',
    97: '',
  },
  pdai: {
    369: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    97: '',
  },
  phex: {
    369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39',
    97: '',
  },
  ehex: {
    369: '0x57fde0a71132198BBeC939B98976993d8D89D225',
    97: '',
  },
  Yayo: {
    369: '0xe37799aE11854708D813Ef7E39a74A795fD58f3A',
    97: '',
  },
}
