import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    hot: 7,
    lpSymbol: 'TONI-USDC LP',
    lpAddresses: {
      97: '',
      369: '0xbA9Fc9f2FBF494a9718B38570411D6c3D79F4d82',
    },
    tokenSymbol: 'TONI',
    tokenAddresses: {
      97: '',
      369: '0x9F8182aD65c53Fd78bd07648a1b3DDcB675c6772',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    risk: 3,
    hot: 7,
    lpSymbol: 'TONI-PLS LP',
    lpAddresses: {
      97: '',
      369: '0x10A639e1860410B075b877e0beBE163F26377A40',
    },
    tokenSymbol: 'TONI',
    tokenAddresses: {
      97: '',
      369: '0x9F8182aD65c53Fd78bd07648a1b3DDcB675c6772',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 2,
    risk: 3,
    lpSymbol: 'PLS-USDC LP',
    lpAddresses: {
      97: '',
      369: '0x6753560538ECa67617A9Ce605178F788bE7E524E',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      369: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 3,
    risk: 3,
    hot: 6,
    isTokenOnly: true,
    lpSymbol: 'TONI',
    lpAddresses: {
      97: '',
      369: '0xbA9Fc9f2FBF494a9718B38570411D6c3D79F4d82', // toni-usdc
    },
    tokenSymbol: 'TONI',
    tokenAddresses: {
      97: '',
      369: '0x9F8182aD65c53Fd78bd07648a1b3DDcB675c6772',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 4,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'PLSX',
    lpAddresses: {
      97: '',
      369: '0x9a34B4064b285BF75ECA1c85b05c6eD45F2BeD28', // PLSX-usdc
    },
    tokenSymbol: 'PLSX',
    tokenAddresses: {
      97: '',
      369: '0x95b303987a60c71504d99aa1b13b4da07b0790ab',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 5,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'XEN',
    lpAddresses: {
      97: '',
      369: '0x84BF7e824e86a40aBCF036ae8F7181B595194dc7', // xen-usdc
    },
    tokenSymbol: 'XEN',
    tokenAddresses: {
      97: '',
      369: '0x8a7FDcA264e87b6da72D000f22186B4403081A2a',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 6,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'pHEX',
    lpSymbolAlt: 'pHEX',
    lpAddresses: {
      97: '',
      369: '0x2cc85b82ce181bce921dc4c0758cfd37a6bc240a', // hex-usdc
    },
    tokenSymbol: 'pHEX',
    tokenAddresses: {
      97: '',
      369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 7,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'INC',
    lpAddresses: {
      97: '',
      369: '0xfbd76fff57cc1ae061b5a73be9bb627c56573414', // inc-usdc
    },
    tokenSymbol: 'INC',
    tokenAddresses: {
      97: '',
      369: '0x2fa878ab3f87cc1c9737fc071108f904c0b0c95d',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 8,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'MORE',
    lpAddresses: {
      97: '',
      369: '0xD02B57269e3909232898b55620f60a981Ee1ccD7', // more-usdc
    },
    tokenSymbol: 'MORE',
    tokenAddresses: {
      97: '',
      369: '0xbEEf3bB9dA340EbdF0f5bae2E85368140d7D85D0',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 9,
    risk: 3,
    lpSymbol: 'MORE-PLS LP',
    lpAddresses: {
      97: '',
      369: '0xbd1364edba35a7284ebac9710894c9b2d5ebf8c5',
    },
    tokenSymbol: 'MORE',
    tokenAddresses: {
      97: '',
      369: '0xbEEf3bB9dA340EbdF0f5bae2E85368140d7D85D0',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 10,
    risk: 3,
    lpSymbol: 'XEN-PLS LP',
    lpAddresses: {
      97: '',
      369: '0x9194fe03E648E1220fA8267D699CeF1A20A6AC88',
    },
    tokenSymbol: 'XEN',
    tokenAddresses: {
      97: '',
      369: '0x06450dee7fd2fb8e39061434babcfc05599a6fb8',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 11,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'WPLS',
    lpAddresses: {
      97: '',
      369: '0x6753560538ECa67617A9Ce605178F788bE7E524E', // WPLS-usdc
    },
    tokenSymbol: 'WPLS',
    tokenAddresses: {
      97: '',
      369: '0xa1077a294dde1b09bb078844df40758a5d0f9a27',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 12,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'WETH',
    lpAddresses: {
      97: '',
      369: '0x6786a4125fd26b11f1013372f6e6483e0e5bef6b', // WETH-usdc
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      97: '',
      369: '0x02DcdD04e3F455D838cd1249292C58f3B79e3C3C',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 13,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'PLSL',
    lpAddresses: {
      97: '',
      369: '0x3d608747f215e6754Ee3259F4453cF4E693Fc947', // WETH-wbnb
    },
    tokenSymbol: 'PLSL',
    tokenAddresses: {
      97: '',
      369: '0x0Fec5F39B3DB85a84F5bfa76935f69095478662e',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 14,
    risk: 3,
    lpSymbol: 'PLSL-PLS LP',
    lpAddresses: {
      97: '',
      369: '0xa6dFf9646075a81b8F5CdB295253b0e833E58458',
    },
    tokenSymbol: 'PLSL',
    tokenAddresses: {
      97: '',
      369: '0x0Fec5F39B3DB85a84F5bfa76935f69095478662e',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 15,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'eHEX',
    lpAddresses: {
      97: '',
      369: '0xb4192f2a7bd6af8465779dc8fd57dcd50610fe91', // ehex-wbnb
    },
    tokenSymbol: 'eHEX',
    tokenAddresses: {
      97: '',
      369: '0x57fde0a71132198BBeC939B98976993d8D89D225',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 16,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'pWBTC',
    lpAddresses: {
      97: '',
      369: '0xe0a31bd1d393774746748bcc17c9ab669f7d4307', // ebtc-usdc
    },
    tokenSymbol: 'pWBTC',
    tokenAddresses: {
      97: '',
      369: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 17,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'eWBTC',
    lpAddresses: {
      97: '',
      369: '0x6fa61478b00370eb1407c1b8fc1fd7db45d84bd3', // ebtc-usdc
    },
    tokenSymbol: 'eWBTC',
    tokenAddresses: {
      97: '',
      369: '0xb17D901469B9208B17d916112988A3FeD19b5cA1',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 18,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'pHDRN',
    lpAddresses: {
      97: '',
      369: '0xa0e55AC5B6464bb519CAE2F68c060aaF32114313', // hdrn-wpls
    },
    tokenSymbol: 'pHDRN',
    tokenAddresses: {
      97: '',
      369: '0x3819f64f282bf135d62168c1e513280daf905e06',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 19,
    risk: 3,
    isTokenOnly: false,
    lpSymbol: 'pHEX-TONI LP',
    lpAddresses: {
      97: '',
      369: '0x32FBC3BE56497859841b0148b4DbebeF8Dd4B4d4', // eHEX-TONI
    },
    tokenSymbol: 'pHEX',
    tokenAddresses: {
      97: '',
      369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 20,
    risk: 3,
    isTokenOnly: false,
    lpSymbol: 'eHEX-TONI LP',
    lpAddresses: {
      97: '',
      369: '0xc4e106bBaEf58515301789D7de5e8dA3f48F6828', // eHEX-TONI
    },
    tokenSymbol: 'eHEX',
    tokenAddresses: {
      97: '',
      369: '0x57fde0a71132198BBeC939B98976993d8D89D225',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 21,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'eUSDC',
    lpAddresses: {
      97: '',
      369: '0x15d38573d2feeb82e7ad5187ab8c1d52810b1f07', // hdrn-wpls
    },
    tokenSymbol: 'eUSDC',
    tokenAddresses: {
      97: '',
      369: '0x15d38573d2feeb82e7ad5187ab8c1d52810b1f07',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 22,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'eDAI',
    lpAddresses: {
      97: '',
      369: '0xefd766ccb38eaf1dfd701853bfce31359239f305', // hdrn-wpls
    },
    tokenSymbol: 'eDAI',
    tokenAddresses: {
      97: '',
      369: '0xefd766ccb38eaf1dfd701853bfce31359239f305',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 23,
    risk: 3,
    isTokenOnly: false,
    lpSymbol: 'PLSX-TONI LP',
    lpAddresses: {
      97: '',
      369: '0xdb449461e31527B184360a31DbeF97F99C2Eb26B', // eHEX-TONI
    },
    tokenSymbol: 'PLSX',
    tokenAddresses: {
      97: '',
      369: '0x95B303987A60C71504D99Aa1b13B4DA07b0790ab',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 24,
    risk: 3,
    lpSymbol: 'PLS-PLSX LP',
    lpAddresses: {
      97: '',
      369: '0x1b45b9148791d3a104184Cd5DFE5CE57193a3ee9',
    },
    tokenSymbol: 'PLSX',
    tokenAddresses: {
      97: '',
      369: '0x95B303987A60C71504D99Aa1b13B4DA07b0790ab',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 25,
    risk: 3,
    isTokenOnly: false,
    lpSymbol: 'eWBTC-pWBTC LP',
    lpAddresses: {
      97: '',
      369: '0x80a600F7Bd4eb31183A23175dC61c269E6d20588', // eHEX-TONI
    },
    tokenSymbol: 'pBTC',
    tokenAddresses: {
      97: '',
      369: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    },
    quoteTokenSymbol: QuoteToken.EBTC,
    quoteTokenAdresses: contracts.ebtc,
  },
  {
    pid: 26,
    risk: 3,
    isTokenOnly: false,
    lpSymbol: 'eHEX-pHEX LP',
    lpAddresses: {
      97: '',
      369: '0x1dA059091d5fe9F2d3781e0FdA238BB109FC6218', // eHEX-TONI
    },
    tokenSymbol: 'eHEX',
    tokenAddresses: {
      97: '',
      369: '0x57fde0a71132198BBeC939B98976993d8D89D225',
    },
    quoteTokenSymbol: QuoteToken.PHEX,
    quoteTokenAdresses: contracts.phex,
  },
  {
    pid: 27,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'pICSA',
    lpAddresses: {
      97: '',
      369: '0xbf5508ee175a48bebbe7383daa3947d7afe7b794', // hdrn-wpls
    },
    tokenSymbol: 'pICSA',
    tokenAddresses: {
      97: '',
      369: '0xfc4913214444aF5c715cc9F7b52655e788A569ed',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 28,
    risk: 3,
    isTokenOnly: false,
    lpSymbol: 'pHEX-PLSX LP',
    lpAddresses: {
      97: '',
      369: '0xa04620601d7dadc3bd1c99312a699ade8e6c16d2', // eHEX-TONI
    },
    tokenSymbol: 'plsx',
    tokenAddresses: {
      97: '',
      369: '0x95b303987a60c71504d99aa1b13b4da07b0790ab',
    },
    quoteTokenSymbol: QuoteToken.PHEX,
    quoteTokenAdresses: contracts.phex,
  },
  {
    pid: 29,
    risk: 3,
    isTokenOnly: false,
    lpSymbol: 'eHEX-PLSX LP',
    lpAddresses: {
      97: '',
      369: '0x4D34D07B5F8A11892Dd8619f30899585B5B0BF2D', // eHEX-TONI
    },
    tokenSymbol: 'plsx',
    tokenAddresses: {
      97: '',
      369: '0x57fde0a71132198BBeC939B98976993d8D89D225',
    },
    quoteTokenSymbol: QuoteToken.EHEX,
    quoteTokenAdresses: contracts.ehex,
  },
  {
    pid: 30,
    risk: 3,
    isTokenOnly: false,
    lpSymbol: 'pWBTC-TONI LP',
    lpAddresses: {
      97: '',
      369: '0xef3E547F9542d83e4d442C98233c6c0e85d0DEBe', // pWBTC-TONI
    },
    tokenSymbol: 'TONI',
    tokenAddresses: {
      97: '',
      369: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 31,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'pAAVE',
    lpAddresses: {
      97: '',
      369: '0xb04998feededf8944e2230a43604f8fa9a2fa539', // pAAVE-wpls
    },
    tokenSymbol: 'pAAVE',
    tokenAddresses: {
      97: '',
      369: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 32,
    risk: 3,
    isTokenOnly: false,
    lpSymbol: 'pHEX-WPLS LP',
    lpAddresses: {
      97: '',
      369: '0xf1F4ee610b2bAbB05C635F726eF8B0C568c8dc65', // pHEX-WPLS LP
    },
    tokenSymbol: 'phex',
    tokenAddresses: {
      97: '',
      369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 33,
    risk: 3,
    isTokenOnly: false,
    lpSymbol: 'eHEX-WPLS LP',
    lpAddresses: {
      97: '',
      369: '0xAa1Ea17e7499f892ab9E45E139D843049942Fb7a', // pHEX-WPLS LP
    },
    tokenSymbol: 'ehex',
    tokenAddresses: {
      97: '',
      369: '0x57fde0a71132198BBeC939B98976993d8D89D225',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 34,
    risk: 3,
    isTokenOnly: false,
    lpSymbol: 'pPEPE-TONI LP',
    lpAddresses: {
      97: '',
      369: '0x7C6926cCD246Daf954236E32f3442d64Da691C6c', // pPEPE-TONI LP
    },
    tokenSymbol: 'ppepe',
    tokenAddresses: {
      97: '',
      369: '0x9F8182aD65c53Fd78bd07648a1b3DDcB675c6772',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 35,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'pPEPE',
    lpAddresses: {
      97: '',
      369: '0x06f6bf96999713418f6742651ff9bb44f83fc84d', // pPEPE-USDC LP
    },
    tokenSymbol: 'pPEPE',
    tokenAddresses: {
      97: '',
      369: '0x6982508145454Ce325dDbE47a25d4ec3d2311933',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 36,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'pLINK',
    lpAddresses: {
      97: '',
      369: '0x2f55fe1e168cc51d44396f1f976e1cc959eaab29', // pLINK-USDC LP
    },
    tokenSymbol: 'pLINK',
    tokenAddresses: {
      97: '',
      369: '0x514910771af9ca656af840dff83e8264ecf986ca',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 37,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'pUNI',
    lpAddresses: {
      97: '',
      369: '0xe80445432d600b727abd61BFbf2f06f272e4b80A', // pUNI-USDC LP
    },
    tokenSymbol: 'pUNI',
    tokenAddresses: {
      97: '',
      369: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 38,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'RBC',
    lpAddresses: {
      97: '',
      369: '0xe65a657a753b2111b5c6402c4cfb74ee4812010b', // RBC-USDC LP
    },
    tokenSymbol: 'RBC',
    tokenAddresses: {
      97: '',
      369: '0x43eaba2E2d2F32f1207A11a18679287Dc7700015',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 39,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'pSHIB',
    lpAddresses: {
      97: '',
      369: '0x21a105c274cfe0f31b5bc38e656108ed48134c77', // pSHIB-USDC LP
    },
    tokenSymbol: 'pSHIB',
    tokenAddresses: {
      97: '',
      369: '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 40,
    risk: 3,
    isTokenOnly: false,
    lpSymbol: 'pSHIB-TONI LP',
    lpAddresses: {
      97: '',
      369: '0xbc53ab6a4f38252b52ac323a97bbb78c6e9f7610', // pSHIB-TONI LP
    },
    tokenSymbol: 'pSHIB',
    tokenAddresses: {
      97: '',
      369: '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 41,
    risk: 3,
    isTokenOnly: false,
    lpSymbol: 'pWBTC-PLSX LP',
    lpAddresses: {
      97: '',
      369: '0xb1f902df906461263fc9b35d1b038962e88aea76', // pWBTC-PLSX
    },
    tokenSymbol: 'pWBTC',
    tokenAddresses: {
      97: '',
      369: '0x95b303987a60c71504d99aa1b13b4da07b0790ab',
    },
    quoteTokenSymbol: QuoteToken.PLSX,
    quoteTokenAdresses: contracts.plsx,
  },
  {
    pid: 42,
    risk: 3,
    isTokenOnly: false,
    lpSymbol: 'pPEPE-PLSX LP',
    lpAddresses: {
      97: '',
      369: '0x68e9296f51f5557f73aee10418ef955d60eb7576', // pWBTC-PLSX
    },
    tokenSymbol: 'pPEPE',
    tokenAddresses: {
      97: '',
      369: '0x95b303987a60c71504d99aa1b13b4da07b0790ab',
    },
    quoteTokenSymbol: QuoteToken.PLSX,
    quoteTokenAdresses: contracts.plsx,
  },
  {
    pid: 43,
    risk: 3,
    isTokenOnly: false,
    lpSymbol: 'pSHIB-PLSX LP',
    lpAddresses: {
      97: '',
      369: '0x17e7db3fAfe3C0b0874e1e8EbFDae64D8d5264d6', // pWBTC-PLSX
    },
    tokenSymbol: 'pSHIB',
    tokenAddresses: {
      97: '',
      369: '0x95b303987a60c71504d99aa1b13b4da07b0790ab',
    },
    quoteTokenSymbol: QuoteToken.PLSX,
    quoteTokenAdresses: contracts.plsx,
  },
  {
    pid: 44,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'PHUX',
    lpAddresses: {
      97: '',
      369: '0x0c5de8b67fab724261776a30b0e067de60a7cf63', // pWBTC-PLSX
    },
    tokenSymbol: 'PHUX',
    tokenAddresses: {
      97: '',
      369: '0x9663c2d75ffd5f4017310405fce61720af45b829',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 45,
    risk: 3,
    isTokenOnly: false,
    lpSymbol: 'INC-TONI LP',
    lpAddresses: {
      97: '',
      369: '0x39f2866f774845631c60c52a693ed9250cca2db5', // pWBTC-PLSX
    },
    tokenSymbol: 'INC',
    tokenAddresses: {
      97: '',
      369: '0x2fa878ab3f87cc1c9737fc071108f904c0b0c95d',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 46,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'TYRH',
    lpAddresses: {
      97: '',
      369: '0x66817ba1d34486553d94f0db29e3b1068aa09f9f', // usdc-tyrh
    },
    tokenSymbol: 'TYRH',
    tokenAddresses: {
      97: '',
      369: '0xc91562626b9a697af683555da9946986278ac9a5',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 47,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'PHIAT',
    lpAddresses: {
      97: '',
      369: '0xD6D6d95DeE58FF2dBEC89EB0637EB7c6491d4CFa', // wpls-PHIAT
    },
    tokenSymbol: 'PHIAT',
    tokenAddresses: {
      97: '',
      369: '0x96E035ae0905EFaC8F733f133462f971Cfa45dB1',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 48,
    risk: 3,
    hot: 4,
    isTokenOnly: true,
    lpSymbol: 'JACK',
    lpAddresses: {
      97: '',
      369: '0x21ca87a9767cBB8c62e2f6939ddE67c770DCC233', // jack-usdc
    },
    tokenSymbol: 'JACK',
    tokenAddresses: {
      97: '',
      369: '0x1f828fc55472Ff3a343F86FAcffafcb0Cb7772a0',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 49,
    risk: 3,
    hot: 4,
    isTokenOnly: false,
    lpSymbol: 'JACK-WPLS LP',
    lpAddresses: {
      97: '',
      369: '0xf3d41f8579998879f8c4e58cc7bc2d21cb6983d3', // JACK-WPLS LP
    },
    tokenSymbol: 'JACK',
    tokenAddresses: {
      97: '',
      369: '0x1f828fc55472Ff3a343F86FAcffafcb0Cb7772a0',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 50,
    risk: 3,
    hot: 4,
    isTokenOnly: false,
    lpSymbol: 'JACK-TONI LP',
    lpAddresses: {
      97: '',
      369: '0x34F8b79D835d93DF3067008Ba9C4614B8514e5aC', // JACK-TONI LP
    },
    tokenSymbol: 'JACK',
    tokenAddresses: {
      97: '',
      369: '0x1f828fc55472Ff3a343F86FAcffafcb0Cb7772a0',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 51,
    risk: 3,
    hot: 5,
    isTokenOnly: true,
    lpSymbol: 'pDAI',
    lpAddresses: {
      97: '',
      369: '0xd38c0cc3e91b1ff87c2b831eb8a172f4a949b44f', // pDAI-USDC LP
    },
    tokenSymbol: 'pDAI',
    tokenAddresses: {
      97: '',
      369: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 52,
    risk: 3,
    dex: 'v2',
    hot: 5,
    isTokenOnly: false,
    lpSymbol: 'pWBTC-pDAI LP',
    lpAddresses: {
      97: '',
      369: '0x78D58247BE592F3Cb7B87A5650c6B466Ce4a5476', // pWBTC-pDAI LP
    },
    tokenSymbol: 'pDAI',
    tokenAddresses: {
      97: '',
      369: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    },
    quoteTokenSymbol: QuoteToken.PBTC,
    quoteTokenAdresses: contracts.pbtc,
  },
  {
    pid: 53,
    risk: 3,
    dex: 'v2',
    hot: 6,
    isTokenOnly: false,
    lpSymbol: 'pDAI-TONI LP',
    lpAddresses: {
      97: '',
      369: '0x373711ad21d1daa5E991137C731f5Bd569e6B177', // pDAI-TONI LP
    },
    tokenSymbol: 'pDAI',
    tokenAddresses: {
      97: '',
      369: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 54,
    risk: 3,
    hot: 6,
    isTokenOnly: true,
    lpSymbol: 'BEAR',
    lpAddresses: {
      97: '',
      369: '0x72070d5ac2F7Be2199e745ffE2365B4e15022bf9', // BEAR-USDC LP
    },
    tokenSymbol: 'BEAR',
    tokenAddresses: {
      97: '',
      369: '0xd6c31bA0754C4383A41c0e9DF042C62b5e918f6d',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 55,
    risk: 3,
    hot: 6,
    isTokenOnly: true,
    lpSymbol: 'ATROPA',
    lpAddresses: {
      97: '',
      369: '0x49f8bfbbd776cdfe4ec967a58a80a7f14aa7c39a', // ATROPA-USDC LP
    },
    tokenSymbol: 'ATROPA',
    tokenAddresses: {
      97: '',
      369: '0xCc78A0acDF847A2C1714D2A925bB4477df5d48a6',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 56,
    risk: 3,
    hot: 6,
    dex: 'v2',
    isTokenOnly: false,
    lpSymbol: 'pDAI-BEAR LP',
    lpAddresses: {
      97: '',
      369: '0x9B2EcC7ffDDF74a67600ee7E5a273561c626C99d', // pDAI-BEAR LP
    },
    tokenSymbol: 'BEAR',
    tokenAddresses: {
      97: '',
      369: '0xd6c31bA0754C4383A41c0e9DF042C62b5e918f6d',
    },
    quoteTokenSymbol: QuoteToken.PDAI,
    quoteTokenAdresses: contracts.pdai,
  },
  {
    pid: 57,
    risk: 3,
    hot: 7,
    isTokenOnly: true,
    lpSymbol: 'SOSA',
    lpAddresses: {
      97: '',
      369: '0x02237444c9c2b9521Ec00F5Ab72B4813B680091b', // SOSA-USDC LP
    },
    tokenSymbol: 'SOSA',
    tokenAddresses: {
      97: '',
      369: '0x307f720a51315dC5597210814aA124a9958a8DBC',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 58,
    risk: 3,
    hot: 6,
    isTokenOnly: true,
    lpSymbol: 'MINT',
    lpAddresses: {
      97: '',
      369: '0x280d780727fb12386f7f63ac563f6dde10f05a3c', // mint-USDC LP
    },
    tokenSymbol: 'MINT',
    tokenAddresses: {
      97: '',
      369: '0x207e6b4529840A4fd518f73c68bc9c19B2A15944',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 59,
    risk: 3,
    hot: 6,
    isTokenOnly: true,
    lpSymbol: 'LOAN',
    lpAddresses: {
      97: '',
      369: '0x46A9d3C223579Fb4532aA9092b13E5bEf28dCFa4', // loan-usdl LP
    },
    tokenSymbol: 'LOAN',
    tokenAddresses: {
      97: '',
      369: '0x9159f1D2a9f51998Fc9Ab03fbd8f265ab14A1b3B',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 60,
    risk: 3,
    hot: 7,
    dex: 'v2',
    isTokenOnly: false,
    lpSymbol: 'SOSA-PLS LP',
    lpAddresses: {
      97: '',
      369: '0x12f193e44a7d8e6cabc09d5ca82586d89220dc31', // SOSA-PLS LP
    },
    tokenSymbol: 'SOSA',
    tokenAddresses: {
      97: '',
      369: '0x307f720a51315dC5597210814aA124a9958a8DBC',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 61,
    risk: 3,
    hot: 7,
    dex: 'v2',
    isTokenOnly: false,
    lpSymbol: 'LOAN-PLS LP',
    lpAddresses: {
      97: '',
      369: '0x6D69654390c70D9e8814B04c69a542632DC93161', // LOan-PLS LP
    },
    tokenSymbol: 'LOAN',
    tokenAddresses: {
      97: '',
      369: '0x9159f1D2a9f51998Fc9Ab03fbd8f265ab14A1b3B',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 62,
    risk: 3,
    hot: 6,
    dex: 'v2',
    isTokenOnly: false,
    lpSymbol: 'USDL-USDC LP',
    lpAddresses: {
      97: '',
      369: '0xE714355DDDacdbCaB8d4dbeA4c04E2Bc483c52Ba', // USDL-USDC LP
    },
    tokenSymbol: 'USDL',
    tokenAddresses: {
      97: '',
      369: '0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 63,
    risk: 3,
    hot: 6,
    dex: 'v2',
    isTokenOnly: false,
    lpSymbol: 'eHEX-pHEX LP',
    lpAddresses: {
      97: '',
      369: '0x922723FC4de3122F7DC837E2CD2b82Dce9dA81d2', // eHEX-pHEX
    },
    tokenSymbol: 'eHEX',
    tokenAddresses: {
      97: '',
      369: '0x57fde0a71132198BBeC939B98976993d8D89D225',
    },
    quoteTokenSymbol: QuoteToken.PHEX,
    quoteTokenAdresses: contracts.phex,
  },
  {
    pid: 64,
    risk: 3,
    hot: 6,
    dex: 'v2',
    isTokenOnly: false,
    lpSymbol: 'INC-PLS LP',
    lpAddresses: {
      97: '',
      369: '0x5b9661276708202dd1a0dd2346a3856b00d3c251', // INC-PLS
    },
    tokenSymbol: 'INC',
    tokenAddresses: {
      97: '',
      369: '0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 65,
    risk: 3,
    hot: 7,
    dex: 'v2',
    isTokenOnly: false,
    lpSymbol: 'SOSA-PLSX LP',
    lpAddresses: {
      97: '',
      369: '0xde84ec3a5bb8c377603e61caf95e552d6268d05b', // SOSA-PLS LP
    },
    tokenSymbol: 'SOSA',
    tokenAddresses: {
      97: '',
      369: '0x307f720a51315dC5597210814aA124a9958a8DBC',
    },
    quoteTokenSymbol: QuoteToken.PLSX,
    quoteTokenAdresses: contracts.plsx,
  },
  {
    pid: 66,
    risk: 3,
    hot: 6,
    isTokenOnly: true,
    lpSymbol: 'pMKR',
    lpAddresses: {
      97: '',
      369: '0x29b8077cD25f5a87e200f62235333E55C253e8B4', // pMKR-usd LP
    },
    tokenSymbol: 'pMKR',
    tokenAddresses: {
      97: '',
      369: '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 67,
    risk: 3,
    hot: 6,
    dex: 'v2',
    isTokenOnly: false,
    lpSymbol: 'pDAI-ATROPA LP',
    lpAddresses: {
      97: '',
      369: '0xe936b1990b98152be81eFaFa40DE6cb8C89eEc0c', // pDAI-ATROPA LP
    },
    tokenSymbol: 'ATROPA',
    tokenAddresses: {
      97: '',
      369: '0xCc78A0acDF847A2C1714D2A925bB4477df5d48a6',
    },
    quoteTokenSymbol: QuoteToken.PDAI,
    quoteTokenAdresses: contracts.pdai,
  },
  {
    pid: 68,
    risk: 3,
    hot: 6,
    dex: 'v2',
    isTokenOnly: false,
    lpSymbol: '2CC-PLS LP',
    lpAddresses: {
      97: '',
      369: '0x5ff4edcd5549aee0464672195e10a562b8921011', // 2CC-PLS LP
    },
    tokenSymbol: '2CC',
    tokenAddresses: {
      97: '',
      369: '0x6293373BF0DAE2050641973D49b7b2f71adC91a1',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 69,
    risk: 3,
    hot: 7,
    dex: 'v2',
    isTokenOnly: false,
    lpSymbol: 'NUTS-PLS LP',
    lpAddresses: {
      97: '',
      369: '0x8850ae6b4abf3f6aa27263f8a7bdfc540ff2cfd0', // NUTS-PLS LP
    },
    tokenSymbol: 'NUTS',
    tokenAddresses: {
      97: '',
      369: '0x97f7259931f98CC64EbCd993fdE03d71716f3E07',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 70,
    risk: 3,
    hot: 7,
    dex: 'v2',
    isTokenOnly: false,
    lpSymbol: 'pWETH-PLS LP',
    lpAddresses: {
      97: '',
      369: '0x7994d526a127979bcb9ec7c98509bb5c7ebd78fd', // pWETH-PLS LP
    },
    tokenSymbol: 'pWETH',
    tokenAddresses: {
      97: '',
      369: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 71,
    risk: 3,
    hot: 7,
    dex: 'v2',
    isTokenOnly: false,
    lpSymbol: 'eHEX-ATROPA LP',
    lpAddresses: {
      97: '',
      369: '0xc48d58e14d510d617c11c6c66356ec1951027b36', // eHEX-ATROPA LP
    },
    tokenSymbol: 'atropa',
    tokenAddresses: {
      97: '',
      369: '0xCc78A0acDF847A2C1714D2A925bB4477df5d48a6',
    },
    quoteTokenSymbol: QuoteToken.EHEX,
    quoteTokenAdresses: contracts.ehex,
  },
  {
    pid: 72,
    risk: 3,
    hot: 7,
    isTokenOnly: true,
    lpSymbol: 'YAYO',
    lpAddresses: {
      97: '',
      369: '0x709699c7db502d782b882490cd58f9ae24c06d9c', // YAYO-USDC LP
    },
    tokenSymbol: 'YAYO',
    tokenAddresses: {
      97: '',
      369: '0xe37799aE11854708D813Ef7E39a74A795fD58f3A',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
]

export default farms
