import { MenuEntry } from '@pancakeswap-libs/uikit'
import urls from 'config/constants/constants'
import { getToniAddress } from 'utils/addressHelpers'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: `${urls.pulseXUrl}?outputCurrency=${getToniAddress()}`,
      },
      {
        label: 'Liquidity',
        href: `${urls.pulseXUrl}add/V1/PLS/${getToniAddress()}`,
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  {
    label: 'Missions',
    icon: 'RoadmapIcon',
    href: '/missions',
  },
  {
    label: 'NFT Auction',
    icon: 'IfoIcon',
    href: '/nft',
  },
  {
    label: 'NFT Farm',
    icon: 'FarmIcon',
    href: '/nftfarm',
  },
  {
    label: 'NFT Collection',
    icon: 'NftIcon',
    href: '/collection',
  },
  {
    label: 'SOSA Lounge',
    icon: 'GroupsIcon',
    href: '/sosalounge',
  },
  {
    label: 'Referral',
    icon: 'VaultsIcon',
    href: '/referral',
  },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'PulseX',
        href: `${urls.pulseXUrl}info/token/${getToniAddress()}`,
      },
      {
        label: 'DexScreener',
        href: 'https://dexscreener.com/pulsechain/0xbA9Fc9f2FBF494a9718B38570411D6c3D79F4d82',
      },
      {
        label: 'CoinMarketCap',
        href: 'https://coinmarketcap.com/currencies/daytona-finance/',
      },
      {
        label: 'CoinGecko',
        href: 'https://www.coingecko.com/en/coins/daytona-finance/',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'NFT White Paper',
        href: '/whitepaper',
      },
      {
        label: 'Github',
        href: 'https://github.com/Daytona-Finance/Contracts/',
      },
    ],
  },
]

export default config
