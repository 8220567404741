import addresses from 'config/constants/contracts'

const chainId = process.env.REACT_APP_CHAIN_ID

export const getToniAddress = () => {
  return addresses.cake[chainId]
}
export const getYayoAddress = () => {
  return addresses.Yayo[chainId]
}
export const getSosaAddress = () => {
  return addresses.sosa[chainId]
}
export const getNftAddress = () => {
  return addresses.nft[chainId]
}
export const getYieldHubAddress = () => {
  return addresses.yieldhub[chainId]
}
export const getMasterChefAddress = () => {
  return addresses.masterChef[chainId]
}
export const getNftAuctionAddress = () => {
  return addresses.nftAuction[chainId]
}

export const getNftAuctionOldAddress = () => {
  return addresses.nftAuction_old[chainId]
}

export const getRouterAddress = () => {
  return '0x98bf93ebf5c380C0e6Ae8e192A7e2AE08edAcc02'
}

export const getRouterAddressV2 = () => {
  return '0x98bf93ebf5c380C0e6Ae8e192A7e2AE08edAcc02'
}
export const getMulticallAddress = () => {
  return addresses.mulltiCall[chainId]
}
export const getWbnbAddress = () => {
  return addresses.wbnb[chainId]
}
export const getLotteryAddress = () => {
  return addresses.lottery[chainId]
}
export const getLotteryTicketAddress = () => {
  return addresses.lotteryNFT[chainId]
}
