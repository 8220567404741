import React, { useEffect, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { ResetCSS } from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import { useFetchPublicData } from 'state/hooks'
import NftGlobalNotification from 'views/NftOld/components/NftGlobalNotification'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import PageLoader from './components/PageLoader'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const NftFarms = lazy(() => import('./views/NftFarm'))
const Referral = lazy(() => import('./views/Referral'))
const NftAuction = lazy(() => import('./views/NFTAuction'))
const SosaLounge = lazy(() => import('./views/SOSALounge'))
const Missions = lazy(() => import('./views/Missions'))

const NftCollection = lazy(() => import('./views/NftCollection'))

const NotFound = lazy(() => import('./views/NotFound'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const { account, connect } = useWallet()
  useEffect(() => {
    if (!account && window.localStorage.getItem('accountStatus')) {
      connect('injected')
    }
  }, [account, connect])

  const RedirectToPDF = () => {
    useEffect(() => {
      window.location.href = `${process.env.PUBLIC_URL}/files/Daytona.Finance%20SOSA%20Yield%20Bearing%20NFT%E2%80%99s.pdf`
    }, [])

    return null // or you can return a loader or any placeholder content
  }

  useFetchPublicData()

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/pools">
              <Farms tokenMode />
            </Route>
            <Route path="/nftfarm">
              <NftFarms />
            </Route>
            <Route path="/referral">
              <Referral />
            </Route>
            <Route path="/nftauction">
              <NftAuction />
            </Route>
            <Route path="/collection">
              <NftCollection />
            </Route>

            {/* Redirect */}
            <Route path="/staking">
              <Redirect to="/pools" />
            </Route>
            <Route path="/nft">
              <Redirect to="/nftauction" />
            </Route>
            <Route path="/whitepaper">
              <RedirectToPDF />
            </Route>
            <Route path="/sosalounge">
              <SosaLounge />
            </Route>
            <Route path="/missions">
              <Missions />
            </Route>
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Menu>
      <NftGlobalNotification />
    </Router>
  )
}

export default React.memo(App)
